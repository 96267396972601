<template>
  <div class="knowledge_bin_wrap">
    <el-header style="height: 60px">
      <home-topbar :is-home="true" :is-personal="true"> </home-topbar>
    </el-header>
    <div class="main" style="height: calc(100% - 60px)">
      <p class="main_tool" @click="toKnowledgeB">
        <i class="el-icon-arrow-left"></i>
        {{ $t("knowledge.ReturnToKnowledgeBase") }}
      </p>
      <div class="main_title">
        <span><i class="el-icon-delete"></i></span>
        <span> {{ $t("knowledge.KnowledgeBaseRecycleBin") }}</span>
        <span style="float: right">
          <span v-limits-of-authority="'KNOWLEDGE_TRUE_DELETE'">
            <el-button
              :disabled="limits_of_authority_disable('KNOWLEDGE_TRUE_DELETE')"
              type="danger"
              @click="deleteFn"
              >{{ $t("knowledge.PermanentlyDelete") }}</el-button
            >
          </span>
        </span>
        <span style="float: right">
          <el-button
            style="margin-right: 20px"
            type="primary"
            @click="recover"
            >{{ $t("knowledge.Restore") }}</el-button
          >
        </span>
      </div>
      <div class="main_table">
        <el-table
          :data="tableData"
          height="700"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            prop="label"
            :label="$t('knowledge.Title')"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <i class="el-icon-document" style="margin-right: 10px"></i>
              {{ scope.row.label }}
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            :label="$t('knowledge.Operator')"
            width="150"
            align="center"
          >
            <template slot-scope="scope">
              {{ matchUserNickname(scope.row.updatedBy, "") }}
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            :label="$t('knowledge.DeletionTime')"
            width="250"
            align="center"
          >
            <template slot-scope="scope">
              {{ new Date(scope.row.deletedAt).Format("yyyy-MM-dd hh:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column type="selection" width="55" align="center">
          </el-table-column>
        </el-table>
        <div style="text-align: center">
          <el-pagination
            :current-page="page.currentPage"
            :page-sizes="[10, 20, 40, 80]"
            :page-size="page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeTopbar from "@/components/homeTopbar";
import { getRecycleBin, recoverBin, deleteBatch } from "@/network/knowledge";
import { mapGetters } from "vuex";
import { limits_of_authority_disable } from "@/utils/limits";
export default {
  components: {
    HomeTopbar,
  },
  data() {
    return {
      is_loading: false,
      tableData: [],
      page: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      selectionList: [],
    };
  },
  computed: {
    ...mapGetters(["user_list"]),
  },
  mounted() {
    this.getRecycleBin();
  },
  methods: {
    limits_of_authority_disable,
    // 回到知识库
    toKnowledgeB() {
      this.$router.push({ path: `/${this.get_pid()}/knowledge` });
    },
    // 复选框选中事件
    handleSelectionChange(selection) {
      this.selectionList = selection;
    },
    // 获取回收站内容
    getRecycleBin() {
      const params = {
        projectId: this.get_pid(),
        params: {
          pageNumber: this.page.currentPage - 1,
          pageSize: this.page.pageSize,
        },
      };
      getRecycleBin(params).then((res) => {
        this.tableData = res.content;
        this.page.total = res.totalElements;
      });
    },
    // 切换最大页数
    handleSizeChange(size) {
      this.page.pageSize = size;
      this.getRecycleBin();
    },
    // 切换页数
    handleCurrentChange(page) {
      this.page.currentPage = page;
      this.getRecycleBin();
    },
    // 恢复
    recover() {
      if (!this.selectionList.length) {
        this.$message({
          type: "warning",
          message: this.$t("knowledge.SelectAtLeastOnePage"),
        });
      } else {
        const documentId = this.selectionList.map((item) => {
          return item.documentId;
        });
        const params = {
          projectId: this.get_pid(),
          documentId: documentId,
        };
        recoverBin(params).then((res) => {
          this.page.currentPage = 1;
          this.getRecycleBin();
        });
      }
    },
    // 删除
    deleteFn() {
      if (!this.selectionList.length) {
        this.$message({
          type: "warning",
          message: this.$t("knowledge.SelectAtLeastOnePage"),
        });
      } else {
        this.$confirm(
          this.$t("knowledge.ConfirmPermanentDeletion"),
          this.$t("review.tip11"),
          {
            confirmButtonText: this.$t("btn.cancelBtn"),
            cancelButtonText: this.$t("btn.deleteBtn"),
            cancelButtonClass: "button_danger",
            type: "warning",
          }
        )
          .then(() => {
            this.$message({
              type: "info",
              message: this.$t("knowledge.DeletionCanceled"),
            });
          })
          .catch(() => {
            const documentId = this.selectionList.map((item) => {
              return item.documentId;
            });
            const params = {
              projectId: this.get_pid(),
              documentId: documentId,
            };
            deleteBatch(params).then((res) => {
              this.page.currentPage = 1;
              this.getRecycleBin();
            });
          });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.knowledge_bin_wrap {
  width: 100%;
  height: 100%;
  text-align: left;
}
.main {
  &_tool {
    color: rgba(166, 166, 166, 1);
    padding: 10px 62px 0 62px;
    cursor: pointer;
  }
  &_title {
    padding: 10px 62px 0 62px;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid rgba(229, 229, 229, 1);
    height: 60px;
    & > span {
      margin-right: 16px;
    }
  }
  &_table {
    padding: 10px 62px 0 62px;
  }
}
</style>
